import React from 'react';
import CookieConsent from 'react-cookie-consent';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import style from './CookieConsentPrompt.module.scss';

export type CookieConsentPromptProps = {
  cookieName?: string;
};

const CookieConsentPrompt = ({ cookieName }: CookieConsentPromptProps) => (
  <CookieConsent
    containerClasses={style.cookieConsent}
    buttonClasses={style.cookieButton}
    declineButtonClasses={style.cookieButton}
    contentClasses={style.cookieContent}
    location="bottom"
    flipButtons
    expires={150}
    buttonText="Acknowledge"
    cookieName={cookieName}
  >
    <InfoOutlinedIcon className={style.cookieIcon} />
    We use cookies to ensure that we give you the best experience on our website. If you continue to
    use this site we will assume that you are happy with it.
  </CookieConsent>
);

export default CookieConsentPrompt;
